import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GreenBar from '../sections/greenBar4'
import Copy from '../sections/about-philosophy'
import { PageHead } from '../common'

export default () => (
  <Layout headImg={`https://s3.amazonaws.com/underwoods/header_img.jpg`}>
    <SEO
      title="About Underwood’s Jewelers Since 1928"
      description="Underwood’s has been the finest jeweler in the South since 1928. Our quality philosophy and loyal customers are the foundation of our stores in Jacksonville and Ponte Vedra, FL." />
    <PageHead>
      <h1>ABOUT UNDERWOOD JEWELERS</h1>
      <p>Underwood’s has been the best jeweler in the South since 1928. We have earned this reputation through our dedication to a long term Quality Philosophy. That being, fine quality will bring customers back and create new customers through the endorsements given to Underwood’s by our satisfied customers in Jacksonville, FL and Ponte Vedra Beach, FL. However, we define quality differently than most stores; because with us, quality is not just materials and craftsmanship, it also includes design, presentation, service, integrity, and your experience in our store. The result of this philosophy is the satisfaction a customer feels when they have chosen that special gift from Underwood’s.</p>
      <p>Underwood’s is a proud member of the American Gem Society (AGS) in which only the most ethical, educated and consumer protective jewelers are qualified to join. In fact, there are only a couple of AGS jewelers in Jacksonville, FL. Through the AGS and Gemological Institute of America (GIA), we are able to send our staff to diamond educational training classes which is a mandatory program within our company; thus, giving us the most educated diamond, jewelry and gemstone staff in Florida.</p>
    </PageHead>
    <GreenBar />
    <Copy />
    <JoinUs />
  </Layout>
)
