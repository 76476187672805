import React from 'react'
import styled from 'styled-components'
import { colors, md } from '../common'

export default () => (
  <Wrap>
    <Copy>
      <h1>THE UNDERWOOD'S PHILOSOPHY</h1>
      <br />
      <p>At Underwood's we are devoted to being the best Jeweler in Jacksonville and Ponte Vedra Beach, FL. We accomplish this through our long-term philosophy that quality in merchandise, presentation, personal service, and our integrity will bring the customer back to shop with us.</p>
    </Copy>
  </Wrap>
)

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/philosophy-bg.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  text-align: center;

  h1 {
    color: ${colors.green};
  }

  ${md} {
    padding:1rem;
  }
`

const Copy = styled.div`
  width:60%;
  margin-left:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
  }

  ${md} {
    margin:0;
    padding: 0;
    width:100%;
  }
`
